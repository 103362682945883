import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './comp/Home.js'
import DogNameCategory from './comp/DogNameCategory.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/dog-name-category/:categoryName' element={<DogNameCategory />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
