import React, { useEffect, useState } from 'react'
import { baseUrl } from '../config';
import axios from 'axios'
import styles from './Home.module.css'
import { Link } from 'react-router-dom';
import BadWordsFilter from 'bad-words';
import { Helmet } from 'react-helmet';
import image from '../assets/22.jpg'

export default function Home() {
  const [button, setButton] = useState('Generate')

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, []);
  const filter = new BadWordsFilter();

  const [error, setError] = useState('');
  const [names, setNames] = useState([]);
  const [groupedNames, setGroupedNames] = useState({});



  const [userInput, setUserInput] = useState({
    message: '',
    temperature: 1,
    tokens: 100,
    sysMessageInput: 'Use object format with the keys category and names; category just includes the theme. 6 names total. Dont explain what you are sending.',
  })

  const [output, setOutput] = useState({
    category: '',
    names: []
  })

  function removeDuplicates(dogNames) {
    const seenNames = new Set(); // A set to keep track of all names we've seen

    dogNames.forEach(category => {
      category.names = category.names.filter(name => {
        if (!seenNames.has(name)) {
          seenNames.add(name);
          return true;
        }
        return false;
      });
    });
    return dogNames;
  }

  const groupByCategory = (namesArray) => {
    const grouped = {};
    namesArray.forEach(item => {
      // Convert the category to lower case for case-insensitive grouping
      const category = item.category?.toLowerCase();
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category] = [...grouped[category], ...item.names];
    });
    return grouped;
  };


  useEffect(() => {
    async function getNames() {
      try {
        const response = await axios.get(`${baseUrl}/names-dogs`)
        // console.log("RESPONSE DATA", response.data);

        if (response.data.length > 0) {
          const cleanedNames = removeDuplicates(response.data);

          const grouped = groupByCategory(cleanedNames);
          setNames(cleanedNames);
          setGroupedNames(grouped);
        }

      } catch (e) {
        setError(e.message)
      }
    }

    getNames()
  }, [])



  function filterCatInput(input) {
    if (filter.isProfane(input)) {
      console.log('INPUT', input);
      return false; // or return filter.clean(name) to censor the inappropriate content
    }
    return true
  }

  function validate() {
    if (userInput.message.length < 1) {
      setError("Please enter a theme.")
      setButton('Generate')
      return false
    }
    const filterCat = filterCatInput(userInput.message)
    if (!filterCat) {
      setError('Bad words detected, please adjust your input.')
      setButton('Generate')
      return false
    }
    return true
  }


  async function submitMessage() {
    setButton('Generating..')
    setError("")


    try {
      let input = userInput;
      console.log(input);

      const validInput = validate();
      if (!validInput) return

      if (!input.message.includes('dog names')) {
        input.message = input.message + ' dog names'
      }

      const response = await axios.post(`${baseUrl}/names`, userInput)

      if (!response.data) {
        setError('No data from server, please contact support')
        return
      } else {

        setUserInput({
          message: '',
          temperature: 1,
          tokens: 100,
          sysMessageInput: 'Use object format with the keys category and names; category just includes the theme. 6 names total. Dont explain what you are sending.',
        })

        setOutput({
          category: response.data.category,
          names: response.data.names
        })
        setButton('Generate')
        setError('')
        return
      }
    } catch (e) {
      setError(e.message || "An error occurred"); // Use the error's message property
    }
  }




  return (
    <div className={styles.wrap}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AI Dog Generator - Free Dog Name Generator - Generate Cute Dog Names, Unique dog Names with AI for free!</title>

        <meta name="description" content='AI Dog Generator - Generate new funny and unique dog names, using our new free dog name generator. Apart of our furry art services, get cute dog names, unique dog names, calico dog names, tuxedo dog names anime dog names, all generated with ai and browse though thousands more.' />
        <link rel="canonical" href='https://ai-dog-generator.com/' />

        <meta property="og:title" content={`AI Dog Generator - Free Dog Name Generator - Get Inspiration For Your Dog's Name`} />
        <meta property="og:url" content='https://ai-dog-generator.com/' />

        <meta property="og:description" content='Generate new funny and unique dog names, using our new free dog name generator. Apart of our furry art services, get cute dog names, unique dog names, calico dog names, tuxedo dog names anime dog names, all generated with ai and browse though thousands more.' />
        <meta property="twitter:description" content='Generate new funny and unique dog names, using our new free dog name generator. Apart of our furry art services, get cute dog names, unique dog names, calico dog names, tuxedo dog names anime dog names, all generated with ai and browse though thousands more.' />
      </Helmet>


      <h1>AI Dog Generator - Free Dog Name Generator</h1>
      <h2>Naming a new dog? Easier now with our AI dog name generator!</h2>

      <p>Generate the perfect name for your dog with our AI-driven Dog Name Generator. <br /> From cute to unique, find inspiration and instantly create a name that matches your dog's personality.</p>
      <small><strong>created by <a href='https://aipetphotos.com'>AiPetPhotos.com</a> & AI</strong></small>

      <div
        className={styles.uiWrapper}
        style={{
          display: 'flex',
        }}>


        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>

          <label className={styles.inputLabel}>
            Enter dog Name Theme:
            <input type='text'
              className={styles.genInput}
              value={userInput.message}
              maxLength="20"
              placeholder='e.g. Sci-fi'
              onChange={(e) => setUserInput(prev => {
                return { ...prev, message: e.target.value }
              })} />

            <button
              disabled={button !== 'Generate'}
              className={styles.submitMessage}
              onClick={submitMessage}>{button}</button>
            <br />
            {error && <p>{error}</p>}
            <small>{userInput.message && `Entered ${userInput.message.length} characters.`} Max characters: 20. </small>

          </label>


          <div className={styles.output}>
            {output.names.length > 0 ?
              <div className={styles.outWrap}>
                <p>Your {output.category}</p>
                <ol>
                  {output.names.length > 0 && output.names.map((name, index) => {
                    return (

                      <li key={index}>{name}</li>
                    )
                  }
                  )}
                </ol>
                <small>Your names will be added to the public directory.</small>

              </div>
              : <p className={styles.outWrap}><b>Your names: </b>Awaiting your theme input</p>}
          </div>
        </div>

        <div>

          <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
            className={styles.bannerWrap}>
            <img className={styles.banner} src={image} alt='dog ai portrait by AiPetPhotos.com' />
            <br />
            <strong>Your Dog AI Portraits<br />
              Get 21 Costumes in 4K</strong><br />
            at AiPetPhotos.com #furryArt
          </a>
        </div>

      </div>

      <h2>Existing Categories</h2>
      <small>Tap a Category to see exisitng names</small>
      <div className={styles.catButtons}>

        {Object.keys(groupedNames).length > 0 &&
          Object.keys(groupedNames).map((category, index) => {
            const encodedString = category.replace(/\s+/g, '-');
            const number = groupedNames[category].length

            return (
              <Link
                // smooth
                key={index}
                to={`/dog-name-category/${encodedString}`}
                className={styles.gotoCat}>
                {category.slice(0, 1).toUpperCase() + category.slice(1)} ({number})
              </Link>
            )
          })}
      </div>

      {/* <div className={styles.cleanedNamesMap}>
        {Object.keys(groupedNames).length > 0 &&

          Object.keys(groupedNames).map((category, index) => {

            return (
              <section className={styles.categoryWrap} key={index} id={category}>
                <h3>{category.slice(0, 1).toUpperCase() + category.slice(1)}
                </h3>

                <div className={styles.map}>
                  {groupedNames[category].length > 0 && groupedNames[category].map((name, index) => (
                    <p key={index} className={styles.nameP}>
                      {index + 1}. <b>{name}</b>
                    </p>
                  ))}
                  <br />

                  {(index % 3 === 0 && index !== 0) && <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
                    className={styles.gotoCat}>
                    Get Dog Portraits
                  </a>}
                </div>
              </section>
            )
          }
          )
        }
      </div> */}

      <p>Got a name? Awesome! Now let's make some art, furry art:</p>

      <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
        className={styles.bannerWrap}>
        <img className={styles.banner} src={image} alt='dog ai portrait' />
        <br />
        <strong>Your Dog in Furry Art Portrait <br />
          Get It Now in 4K</strong><br />
        at AiPetPhotos.com
      </a>

      <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
        className={styles.callAction}>
        Checkout Our Dog Portraits
      </a>

      <li><a href='https://furry-art.com/' className={styles.LinkComp}>Free Pet Name Generator - Furry Art &#8599;</a></li>
      <li><a href='https://aicatz.com' className={styles.LinkComp}>Free Cat Name Generator - AiCatz &#8599;</a></li>

    </div >
  )
}