import React, { useState } from 'react'
import { baseUrl } from '../config';
import axios from 'axios'
import { useParams, Link } from "react-router-dom"
import styles from './DogNameCategory.module.css'
import image from '../assets/22.jpg'
import { Helmet } from 'react-helmet';


export default function DogNameCategory() {

  const [error, setError] = useState('');
  const [groupedNames, setGroupedNames] = useState({});
  const { categoryName } = useParams()

  function removeDuplicates(petNames) {
    const seenNames = new Set(); // A set to keep track of all names we've seen
    petNames.forEach(category => {
      category.names = category.names.filter(name => {
        if (!seenNames.has(name)) {
          seenNames.add(name);
          return true;
        }
        return false;
      });
    });
    return petNames;
  }

  const groupByCategory = (namesArray) => {
    const grouped = {};
    namesArray.forEach(item => {
      // Convert the category to lower case for case-insensitive grouping
      const category = item.category?.toLowerCase();
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category] = [...grouped[category], ...item.names];
    });
    return grouped;
  };


  React.useEffect(() => {
    async function getNames() {
      try {

        const response = await axios.get(`${baseUrl}/names-dogs/${categoryName}`)

        if (response.data.length > 0) {
          const cleanedNames = removeDuplicates(response.data);
          const grouped = groupByCategory(cleanedNames);
          setGroupedNames(grouped);
        }

      } catch (e) {
        setError(e.message)
      }
    }
    getNames();

  }, [categoryName]);

  const decodedString = categoryName.replace(/-/g, ' ');
  const title = `Dog names generated by AI - category is ${decodedString}`
  const description = `Dog names for the category ${decodedString} are generated by AI at ai-dog-generator.com but are prompted by humans - explore the names and share.`
  const canonUrl = `https://ai-dog-generator.com/dog-name-category/${categoryName}`;

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>

        <meta name="description" content={title} />
        <link rel="canonical" href={canonUrl} />

        <meta property="og:title" content={title} />
        <meta property="og:url" content={canonUrl} />

        <meta property="og:description" content={description} />
        <meta property="twitter:description" content={description} />
      </Helmet>

      <div className={styles.cleanedNamesMap}>
        {Object.keys(groupedNames).length > 0 &&

          Object.keys(groupedNames).map((category, index) => {


            return (
              <section className={styles.categoryWrap} key={index} id={category}>
                <h3>{category.slice(0, 1).toUpperCase() + category.slice(1)}
                </h3>

                <div className={styles.map}>
                  {groupedNames[category].length > 0 && groupedNames[category].map((name, index) => (
                    <p key={index} className={styles.nameP}>
                      {index + 1}. <b>{name}</b>
                    </p>
                  ))}

                </div>
              </section >
            )
          }
          )
        }
      </div>

      <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
        className={styles.bannerWrap}>
        <img className={styles.banner} src={image}
          alt='dog ai portrait by AiPetPhotos.com' />
        <br />
        <strong>Your Dog in AI Portraits <br />
          Get 21 Characters in 4K</strong><br />
        at AiPetPhotos.com #furryArt
      </a>
      <a href='https://aipetphotos.com/product/dog-portrait-ai-4k'
        className={styles.callAction}>
        Checkout Our Doc Portraits
      </a>



      <Link to='/'>Back to Generator</Link>

    </div>
  )
} 